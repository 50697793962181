import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b41063e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "dropdown",
  ref: "dropdownRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", {
      class: _normalizeClass(_ctx.isOpen? 'nav-link' : 'nav-link down'),
      id: "navbarDropdown",
      role: "button",
      "data-bs-toggle": "dropdown",
      "aria-expanded": "false",
      onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toogleOpen && _ctx.toogleOpen(...args))),
      onMouseleave: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeOpen && _ctx.closeOpen(...args)), ["prevent"]))
    }, _toDisplayString(_ctx.name), 35),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("ul", {
          class: "dropdown-menu",
          "aria-labelledby": "navbarDropdown",
          onMouseenter: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toogleOpen && _ctx.toogleOpen(...args))),
          onMouseleave: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.closeOpen && _ctx.closeOpen(...args)), ["prevent"]))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 544), [
          [_vShow, _ctx.isOpen]
        ])
      ]),
      _: 3
    })
  ], 512))
}