
import { defineComponent, ref, Ref } from 'vue'
import MobileDropdownItem from './MobileDropdownItem.vue'
import Dropdown from './Dropdown.vue'
import DropdownItem from './DropdownItem.vue'
import useScrollDistance from '../hooks/useScrollDistance'
import { active } from '../router'
import linkGo, { linkWorld } from '../hooks/links'
export default defineComponent({
  name: 'GlobalHeader',
  components: {
    MobileDropdownItem,
    Dropdown,
    DropdownItem
  },
  setup () {
    const mobileIsOpen = ref<boolean>(false)
    const writeOrBlack = ref<boolean>(true)
    const mobilToogleOpen = () => {
      mobileIsOpen.value = !mobileIsOpen.value
    }
    
    const scrollTop = useScrollDistance()
    return {
      mobileIsOpen,
      mobilToogleOpen,
      scrollTop,
      writeOrBlack,
      active,
      linkGo,
      linkWorld
    }
  }
})
