import { onMounted, onUnmounted, ref, Ref } from 'vue'
export default function useScrollDistance ():Ref<number> {
  const scrollTop = ref<number>(0)
  const handerScroll = () => {
    const scroll = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
    scrollTop.value = scroll
  }

  onMounted(() => {
    document.addEventListener('scroll', handerScroll)
  })
  onUnmounted(() => {
    document.addEventListener('scroll', handerScroll)
  })
  return scrollTop
}
