
import { defineComponent } from 'vue'
import GlobalHeaderVue from './components/Header.vue'
import GlobalFooter from './components/Footer.vue'
import { headTailVis } from './router'
export default defineComponent({
  name: 'App',
  components: {
    GlobalHeaderVue,
    GlobalFooter
  },
  setup () {
    return {
      headTailVis
    }
  }
})
