
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MobileDropdownItem',
  components: {},
  setup () {
    return {
    }
  }
})
