import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalHeaderVue = _resolveComponent("GlobalHeaderVue")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_GlobalFooter = _resolveComponent("GlobalFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(!_ctx.headTailVis? 'all' : 'all information')
    }, [
      (!_ctx.headTailVis)
        ? (_openBlock(), _createBlock(_component_GlobalHeaderVue, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ], 2),
    (!_ctx.headTailVis)
      ? (_openBlock(), _createBlock(_component_GlobalFooter, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}