import { useRouter } from "vue-router"
const router = useRouter()
const linkGo = (link: string) => {
  window.location.href = link
}

export const linkWorld = (link: string) => {
  window.open(link)
}

export const routGo = (path: string) => {
  window.location.href = path
}

export default linkGo