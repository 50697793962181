
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GlobalFooter',
  components: {},
  setup () {
  const openurl =()=>{
    window.open('http://imcgpt.baihai.co:9997/information')
  }
    return {
    openurl
    }
  }
})
