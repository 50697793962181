import { ref, Ref, onMounted, onUnmounted } from 'vue'

export default function useClickoutSider (eventRef: Ref<null | HTMLElement>): Ref<boolean> {
  const isClickOutside = ref(false)

  const handler = (e: MouseEvent) => {
    if (e.target) {
      if (eventRef.value?.contains(e.target as HTMLElement)) {
        isClickOutside.value = true
      } else isClickOutside.value = false
    }
  }

  onMounted(() => {
    document.addEventListener('click', handler)
  })
  onUnmounted(() => {
    document.removeEventListener('click', handler)
  })
  return isClickOutside
}
