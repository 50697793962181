
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { emitter } from './Dropdown.vue'
export default defineComponent({
  name: 'Dropdownitem',
  props: {
    to: {
      type: String,
      defaut: '/'
    }
  },
  components: {},
  setup (props) {
    const router = useRouter()
    const skip = () => {
      router.push(props.to as string)
      emitter.emit('close-open', null)
    }

    return {
      skip
    }
  }
})
