
// import { active } from '../router'
import { defineComponent, ref, watch, onUnmounted } from 'vue'
import useClickoutSider from '../hooks/useClickoutSider'
import useScrollDistance from '../hooks/useScrollDistance'
import mitt from 'mitt'
export const emitter = mitt()
export default defineComponent({
  name: 'Dropdown',
  components: {},
  props: {
    name: {
      required: true,
      type: String
    }
  },
  setup () {
    const isOpen = ref(false)
    const dropdownRef = ref<null | HTMLElement>(null)
    const toogleOpen = () => {
      isOpen.value = true
    }
    const isClickoutSider = useClickoutSider(dropdownRef)
    const scrollTop = useScrollDistance()
    watch(isClickoutSider, () => {
      if (!isClickoutSider.value) {
        isOpen.value = false
      }
    })
    const closeOpen = () => {
      isOpen.value = false
    }
    emitter.on('close-open', closeOpen)
    onUnmounted(() => {
      emitter.all.clear()
    })
    return {
      isOpen,
      toogleOpen,
      dropdownRef,
      scrollTop,
      closeOpen
      // active
    }
  }
})
