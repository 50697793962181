import { createRouter, createWebHistory } from 'vue-router'
import { ref } from 'vue'
export const headTailVis = ref<boolean | null>(false)
export const active = ref<number>(0)
const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./view/Home.vue')
    },
    {
      path: '/studio',
      name: 'studio',
      component: () => import('./view/Studio.vue')
    },
    {
      path: '/engine',
      name: 'engine',
      component: () => import('./view/Engine.vue')
    },
    {
      path: '/price',
      name: 'price',
      component: () => import('./view/Price.vue')
    },
    {
      path: '/customerstories',
      name: 'customerstories',
      component: () => import('./view/CustomerStories.vue')
    },
    {
      path: '/unite',
      name: 'unite',
      component: () => import('./view/Unite.vue')
    },
    {
      path: '/recruit',
      name: 'recruit',
      component: () => import('./view/Recruit.vue')
    },
    {
      path: '/introduce',
      name: 'introduce',
      component: () => import('./view/Introduce.vue')
    },
    {
      path: '/information',
      name: 'information',
      component: () => import('./view/SubmitInformation.vue')
    },
    {
      path: '/aigc',
      name: 'aigc',
      component: () => import('./view/AIGC.vue')
    },
    {
      path: '/victory',
      name: 'victory',
      component: () => import('./view/Success.vue')
    },
    {
      path: '/gpucalc',
      name: 'gpucalc',
      component: () => import('./view/Gpucalc.vue')
    }
  ],
  scrollBehavior () {
    return { top: 0 }
  }
})
router.beforeEach((to, from, next) => {
  if (to.name === 'information' || to.name === 'victory') {
    headTailVis.value = true
  } else headTailVis.value = false
  switch (to.name) {
    case 'home':
      active.value = 0
      break
    case 'studio':
    case 'engine':
    case 'aigc':
      active.value = 1
      break
    case 'price':
    case 'introduce':
      active.value = 2
      break
    case 'customerstories':
      active.value = 3
      break
    case 'unite':
      active.value = 4
      break
    case 'recruit':
      active.value = 6
    break
    case 'gpucalc':
      active.value = 7
    break
    default:
      active.value = NaN
      break
  }
  next()
})

export default router
