import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import FastClick from 'fastclick'
import 'animate.css'
// 这块一定要加,否者会有部分动画无法执行
import 'animate.css/animate.compat.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/style/index.less'
import './hooks/BaiduStatistics'
import "swiper/swiper.min.css"
// FastClick.attach(document.body)
// import myWOW from './hooks/wow'
// // 初始化 wow.js
// myWOW()
createApp(App)
  .use(router)
  .mount('#app')
